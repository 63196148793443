<template>
    <div class="wraper">
        <div class="wrap-mainer">
            <div class="sidebar">
                <div class="treeside"></div>
                <div class="treetitle">权限列表</div>
                <div v-if="tree_show" class="tree_wrapper">
                    <ltree ref="ltreeref" w="230px" ot="only" :dt="1" :it="2" :data="tree" all="null" :all_menu="all_menu" :menu="menu_arr" :callback="treeCallback" :renameDo="renameDo"></ltree>
                </div>
                <div class="ceratbtn" @click="creatfileall()">新建文件夹</div>
            </div>
            <div v-if="item_show" class="wrap-container">
                <Item ref="itemrefss" :reload="toReload"></Item>
            </div>
        </div>
    </div>
</template>
<script>
    import ltree from '../../../components/sa0/ltree/ltree.vue'
    import Item from './item/item'

    export default {
        name: "zhixingbiaozhun",
        components: {ltree, Item},
        data() {
            return {
                menu_arr: [],
                all_menu: [],
                rename_input: '',
                creatname_input: '',
                creatname_input1: '',
                item_show: false,
                rename: false,
                creatfile: false,
                creatfile1: false,
                nav_show: true,
                rename_info: false,
                creatname_info: false,
                Itemdir_show: false,
                active_item: false,
                tree_show: false,
                menu: [],
                fa_menu: [],
                tree: [],
                click_one: false,
                click_one_do: false,
                first_son: false,
                pid: 0,
                tree_active_id: [],
                editid: 0
            }
        },

        watch: {
            $route: {
                handler() {
                    this.onMounted()//列表
                },
                deep: true,
            },
        },
        mounted() {
            this.onMounted()//列表
        },
        methods: {
            onMounted() {
                let qid = Number(this.$route.query.id) || false;
                this.editid = Number(this.$route.query.id) || 0;
                console.log(qid)
                if (!qid || qid === 0) {
                    this.method_id = 0
                }
                this.GMethod_category_alllist(Number(this.$route.query.id) || 0);
                setTimeout(() => {
                    this.tree_show = true
                }, 100);
                // this.treeCallback([{
                //     id: "71"
                // }])

            },
            menuarrmaker() {
                this.all_menu = [{
                    name: '新建文件夹',
                    show: -1,
                    func: () => {
                        console.log('根目录 新建文件夹')
                        this.creatfileall()  //全部新建文件夹
                    }
                }]
                this.menu_arr = [{
                    name: '重命名',
                    show: -1,
                    func: (item) => {
                        this.$refs['ltreeref'].renameActive(item.id)
                    }
                }, {
                    name: '新建内容',
                    show: 1, //  -1 全部 0-文件夾 1-内容
                    c_show: 2, //  -1 全部 0-文件夾 1-内容
                    func: (item) => {
                        console.log(item, '新建内容')
                        this.creatcontant(item)
                    }
                }, {
                    name: '新建文件夹',
                    show: 1,
                    c_show: 1,
                    func: (item) => {
                        console.log(item, '新建文件夹')
                        this.creatfilemain(item)   //下面的新建文件夹
                    }
                },
                    {
                        name: '创建副本',
                        show: -1,
                        func: (item) => {
                            console.log(item, '创建副本')
                            this.copyfbclick(item)


                        }
                    }, {
                        name: '上移',
                        show: -1,
                        func: (item) => {
                            console.log(item, '上移')

                            this.UPitemlist(item)
                        }
                    }, {
                        name: '下移',
                        show: -1,
                        func: (item) => {
                            console.log(item, '下移')

                            this.Downitemlist(item)
                        }
                    },
                    {
                        name: '删除',
                        show: -1,
                        func: (item) => {
                            console.log(item, '删除')
                            this.delclick(item)
                        }
                    },]
            },

            // 获取树列表
            GMethod_category_alllist(id = 0) {
                this.$sa0.post({
                    url: this.$api('Authalllist'),
                    data: {},
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.tree = this.makeList(response.data.list);
                            if (id !== 0) {
                                setTimeout(() => {
                                    this.$refs['ltreeref'].renameActive('no')
                                }, 10000000)
                            }
                            if (id === 0 && this.first_son) {
                                this.$router.push({
                                    name: this.$route.name,
                                    query: {id: this.first_son.id}
                                })
                                return
                            }
                            this.tree_show = true
                            setTimeout(() => {
                                this.$refs['ltreeref'].openGroup(this.tree_active_id)
                            }, 100)
                            this.menuarrmaker()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            renameDo(item, name) {
                this.$sa0.post({
                    url: this.$api('Rename_auth'),
                    data: {
                        name: name,
                        id: item.id
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.rename = false;
                            layer.msg(response.message)
                            // this.GMethod_category_alllist(Number(this.$route.query.id) || 0)
                            this.$refs['ltreeref'].renameActive('no')
                            this.$router.push({
                                name: this.$route.name,
                                query: {id: item.id, t: new Date() / 1}
                            })
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            creatfileall() {
                this.$sa0.post({
                    url: this.$api('Create_auth'),
                    data: {
                        name: '新建文件夹',
                        pid: 0,
                        type: 1,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.GMethod_category_alllist(response.data.id)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            creatfilemain(item) {
                this.$sa0.post({
                    url: this.$api('Create_auth'),
                    data: {
                        name: '新建文件夹',
                        pid: item.id,
                        type: 1,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.GMethod_category_alllist(response.data.id)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            creatcontant(item) {
                this.$sa0.post({
                    url: this.$api('Create_auth'),
                    data: {
                        name: '新建权限',
                        pid: item.id,
                        type: 2,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            // this.GMethod_category_alllist(response.data.id)
                            this.$router.push({
                                name: this.$route.name,
                                query: {id: response.data.id}
                            })
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            UPitemlist(item) {
                this.$sa0.post({
                    url: this.$api('Set_auth_sort'),
                    data: {
                        auth_id: item.id,
                        type: 1
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.GMethod_category_alllist(Number(this.$route.query.id) || 0)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            Downitemlist(item) {
                this.$sa0.post({
                    url: this.$api('Set_auth_sort'),
                    data: {
                        auth_id: item.id,
                        type: 2
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.GMethod_category_alllist(Number(this.$route.query.id) || 0)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            delclick(item) {
                this.$confirm({
                    title: '确认删除该项?',
                    content: '',
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk: () => {
                        let data = {
                            id: item.id,
                        }
                        this.$sa0.post({
                            url: this.$api('Del_auth'),
                            data: data,
                        }).then((response) => {
                            this.$sa0.response({
                                response: response.data,
                                then: (res) => {
                                    layer.msg(res.message)
                                    this.GMethod_category_alllist(Number(this.$route.query.id) || 0)
                                },
                                error: (res) => {
                                    layer.msg(res.message)
                                }
                            })
                        })
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });
            },


            copyfbclick(item) {
                this.$sa0.post({
                    url: this.$api('Copy_auth'),
                    data: {
                        auth_id: item.id,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.GMethod_category_alllist(Number(this.$route.query.id) || 0)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            treeCallback(item) {
                let info = item[item.length - 1]
                if (info.id === 'all') {
                    this.$router.push({
                        name: this.$route.name,
                        query: {id: 0}
                    })
                    return
                }
                if (info.id !== 'all' && info.type === 0) return;
                this.$router.push({
                    name: this.$route.name,
                    query: {id: info.id}
                })
            },

            makeList(list, pid = []) {
                let qid = Number(this.$route.query.id) || 0;
                return list.map((item) => {
                    // let
                    if (qid && qid === Number(item.id)) {
                        this.tree_active_id = [...pid, item.id]
                        this.deep_info = item
                        this.item_show = false
                        setTimeout(() => {
                            this.item_show = true
                            setTimeout(() => {
                                this.$refs.itemrefss.method_id = this.deep_info.id
                                this.$refs.itemrefss.getMethodInfo(this.deep_info.id)
                            })
                        })
                    }
                    if (!this.first_son && item.type === 2) {
                        this.first_son = item;
                    }
                    return {
                        'id': Number(item.id),
                        "type": item.type,
                        "name": item.name,
                        "children": item.child.length === 0 ? [] : this.makeList(item.child, [...pid, item.id]),
                        "content": {
                            "pids": pid,
                            "pid": item.pid,
                        }
                    }
                })
            },

            toReload() {
                this.tree_show = false
                this.item_showall = false
                this.GMethod_category_alllist(Number(this.$route.query.id) || 0)
                setTimeout(() => {
                    this.tree_show = true
                })
            },

        }
    }
</script>

<style scoped>
    .w200{ width: 200px}

    .w100{ width: 100px}

    .tree_wrapper{
        position: relative;
        height: 800px;
        overflow-y: auto;
        margin-left: -20px;
        }

    .wraper{ width: 100%; height: 100%; }

    .wrap-mainer{ display: flex }

    .wrap-container{ flex: 1; margin-left: -100px}

    .sidebar{ width: 330px; }

    .treeside{ position: fixed; width: 230px; height: calc(100vh - 110px); border: 1px solid #eeeeee; left: 0; border-left: none}

    .treetitle{ position: fixed; width: 230px; line-height: 40px; font-size: 14px; font-weight: bold; background: #f9f9f9; left: 0; padding-left: 10px; z-index: 9999 }

    .ceratbtn{ width: 230px; background: #1890ff; color: #ffffff; text-align: center; line-height: 40px; position: fixed; bottom: 0; left: 0; cursor: pointer; z-index: 99999999}
</style>
