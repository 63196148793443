<template>
    <div>
        <div style="width: 1000px">
            <table class="layui-table" lay-size="sm">
                <thead>
                <tr>
                    <th style="width: 200px; text-align: left !important">权限名称</th>
                    <th style="text-align: left !important;">
                        <a-input v-model="form1.name" class="w300"/>
                    </th>
                </tr>
                <tr>
                    <th colspan="2" style="font-weight: bold; text-align: left !important">页面权限</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(sidebar_list_item,sidebar_list_key) in auth_list.sidebar_list">
                    <td style="text-align: left">
                        <a-checkbox v-model="sidebar_list_item.check" @change="checkPageAll(sidebar_list_key)">
                            {{sidebar_list_item.meta_title }}
                        </a-checkbox>
                    </td>
                    <td>
                        <div class="authmain">
                            <div class="authmainlist" v-for="(sidebar_list_item_children_item,sidebar_list_item_children_key) in sidebar_list_item.children">
                                <a-checkbox :label="sidebar_list_item_children_item.meta_title" v-model="auth_list.sidebar_list[sidebar_list_key].children[sidebar_list_item_children_key].check" @change="onChange">
                                    {{sidebar_list_item_children_item.meta_title}}
                                </a-checkbox>
                            </div>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>


            <table class="layui-table" style="margin-top: -11px" lay-size="sm">
                <thead>
                <tr>
                    <th colspan="2" style="font-weight: bold; text-align: left !important;">操作权限</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(api_list_item,api_list_key) in auth_list.api_list">
                    <td style="text-align: left; width: 200px ">
                        <a-checkbox v-model="api_list_item.check" @change="checkcaozuoAll(api_list_key)">
                            {{ api_list_item.title }}
                        </a-checkbox>
                    </td>
                    <td>
                        <div class="authmain">
                            <div class="authmainlist" v-for="(api_list_item_children_item,api_list_item_children_key) in api_list_item.children">
                                <a-checkbox :label="api_list_item_children_item.title" v-model="auth_list.api_list[api_list_key].children[api_list_item_children_key].check" @change="onChange">
                                    {{api_list_item_children_item.title}}
                                </a-checkbox>
                            </div>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
            <div style="text-align: right">
                <a-button type="primary" html-type="submit" style="width: 150px; height: 40px" @click="doClick1()">
                    保存
                </a-button>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "creatitem",
        props: ["reload", "item_info"],
        components: {},
        data() {
            return {
                expandedKeys: [],
                openArr: [0],
                do_type: false,
                createfenleimain: false,
                createfenlei: false,
                pid: 0,
                node_list: [],
                showLine: true,
                showIcon: false,
                labelCol: {span: 4},
                wrapperCol: {span: 14},
                auth_list: false,
                form: {
                    name: '',
                    region: '0',
                },
                form1: {},
                all_check: false,
            }
        },
        watch: {},
        mounted() {
            console.log('---------------')
            console.log(this.item_info)
            this.pid = this.item_info.auth.id
            console.log('---------------')
            console.log('---------------')
            this.getAuthList();
            this.form1 = JSON.parse(JSON.stringify(this.item_info))
        },
        methods: {
            checkPageAll(sidebar_list_key) {
                this.auth_list.sidebar_list[sidebar_list_key].children.map((item, key) => {
                    this.$set(this.auth_list.sidebar_list[sidebar_list_key].children[key], 'check', this.auth_list.sidebar_list[sidebar_list_key].check)
                })
            },
            checkcaozuoAll(api_list_key) {
                this.auth_list.api_list[api_list_key].children.map((item, key) => {
                    this.$set(this.auth_list.api_list[api_list_key].children[key], 'check', this.auth_list.api_list[api_list_key].check)
                })
            },
            onChange(e) {
                console.log(`checked = ${e.target.checked}`);
            },
            getAuthList() {
                this.$sa0.post({
                    url: this.$api('Get_admin_auth'),
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            let sidebar_list = response.data.list1.map((item) => {
                                item.children = item.children.map((i) => {
                                    i.check = false;
                                    return i;
                                });
                                item.check = false;
                                return item;
                            });
                            let api_list = response.data.list3.map((item) => {
                                item.children = item.children.map((i) => {
                                    i.check = false;
                                    return i;
                                });
                                item.check = false;
                                return item;
                            });
                            this.auth_list = {
                                api_list, sidebar_list
                            };
                            this.getItemInfo()
                            if (this.pid == 0) {
                                // this.form = {
                                //     id: '0',
                                //     name: '',
                                // };
                                this.form1 = {
                                    id: 0,
                                    name: '',
                                    show: 1,
                                    type: 2,
                                    status: 1,
                                    pid: '0',
                                    auth: '',
                                };
                                // this.createfenlei = true;
                                // this.createfenleimain = false;
                                this.do_type = false;
                            } else {

                            }
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            getItemInfo() {
                this.do_type = true
                this.$sa0.post({
                    url: this.$api('Get_auth_detail'),
                    data: {
                        id: this.pid
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {


                            this.form_word = response.data.auth;
                            // this.expandedKeys = ((this.form_word.auth_ids).toString()).split(',').map((item) => {
                            //     return Number(item)
                            // })
                            this.openArr = [this.form_word.pid]
                            // if (this.form_word.type == 1) {
                            //     this.createfenlei = true;
                            //     this.createfenleimain = false;
                            // } else {
                            //     this.createfenlei = false;
                            //     this.createfenleimain = true;
                            // }
                            // this.form = {
                            //     id: this.form_word.id,
                            //     name: this.form_word.name,
                            // }
                            // this.form1 = {
                            //     id: this.form_word.id,
                            //     name: this.form_word.name,
                            // }

                            let auth_arr = this.form_word.auth.split(",").map((item) => {
                                return Number(item)
                            });


                            this.form1 = {
                                id: this.form_word.id,
                                name: this.form_word.name,
                                show: 1,
                                type: 2,
                                status: 1,
                                pid: this.form_word.pid,
                                auth: this.form_word.auth,
                            };


                            let api_list = this.auth_list.api_list
                            let sidebar_list = this.auth_list.sidebar_list


                            let al = api_list.map((item) => {
                                let data_arr = item.children.map((i) => {
                                    if (auth_arr.indexOf(Number(i.id)) !== -1) {
                                        i.check = true
                                    }
                                    return i
                                })
                                item.check = false;
                                item.children = data_arr;
                                return item;
                            })
                            this.auth_list.api_list = al;


                            let sl = sidebar_list.map((item) => {
                                let data_arr = item.children.map((i) => {
                                    if (auth_arr.indexOf(Number(i.id)) !== -1) {
                                        i.check = true
                                    }
                                    return i
                                })
                                item.check = false;
                                item.children = data_arr;
                                return item;
                            })
                            this.auth_list.sidebar_list = sl;

                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            doClick1() {
                let auth_arr = [];
                this.auth_list.sidebar_list.map((item) => {
                    item.children.map((i) => {
                        if (i.check) auth_arr.push(i.id)
                    });
                });
                this.auth_list.api_list.map((item) => {
                    item.children.map((i) => {
                        if (i.check) auth_arr.push(i.id)
                    });
                });
                let data = {
                    id: this.form1.id,
                    name: this.form1.name,
                    show: 1,
                    type: 2,
                    status: 1,
                    pid: this.form1.pid,
                    auth: auth_arr.join(","),
                }
                console.log(JSON.stringify(data))
                this.$sa0.post({
                    url: this.do_type ? this.$api('Edit_auth') : this.$api('Create_auth'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            this.getAuthList();
                            this.reload();
                            layer.msg(res.message)
                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },

        }
    }
</script>

<style scoped>
    .ml5{ margin-left: 5px}

    .ant-table-column-sorter{
        display: flex;
        flex-direction: column;
        margin-top: 1px;
        margin-left: 5px;
        cursor: pointer;
        }

    .jiantou{
        color: #999999
        }

    .classname{
        color: #1890ff;
        }

    .fl{
        float: left
        }

    .wraper{ width: 100%; height: 100%; display: flex;}

    .wrap-mainer{ flex: 1;display: flex; }

    .sidebar{ width: 200px; display: flex; flex-direction: column}

    .sidebarmain{ flex: 1; overflow-y: auto}

    .wrap-container{ flex: 1; overflow-y: auto}

    .addbutton{ width: 100%; text-align: center; bottom: 0; height: 40px; background: #1890ff}

    .addbuttonbtn{ width: 100%; color: #ffffff; line-height: 40px; cursor: pointer; height: 40px}


    .bgc{ margin-right: 20px; border: 1px solid #eeeeee;}

    .samplelefttit{ background: #f9f9f9; padding: 10px; border-bottom: 1px solid #eeeeee; font-weight: bold }


    .createsmaplewrap{ width: 1000px; }

    .createsmaple{ width: 800px; display: flex; justify-content: space-between}

    .createsmapletit{ font-size: 20px; font-weight: bold}

    .mt20{ margin-top: 20px}

    .w300{ width: 200px}

    .ant-form-text{ display: inline-block; padding-left: 20px; cursor: pointer}

    .ml20{ margin-left: 20px}

    .admin_account_wrapper{
        opacity: .5;
        }

    .sidebar_list_item_children_wrapper{
        margin-bottom: 10px;
        }

    .api_list_item_children_wrapper{
        margin-bottom: 10px;
        }

    .api_item_remark_wrapper{
        margin-left: 24px;
        }

    .api_remark_wrapper{
        color: #5a5e66;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        }

    .sidebar_item_remark_wrapper{
        margin-left: 24px;
        }

    .sidebar_remark_wrapper{
        color: #5a5e66;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        }

    .api_list_wrapper{
        margin-bottom: 30px;
        }

    .api_list_item_wrapper{
        width: 25%;
        padding: 10px;
        display: inline-block;
        vertical-align: top;
        }


    .sidebar_list_wrapper{
        margin-bottom: 30px;
        margin-top: 30px;

        }

    .sidebar_list_item_wrapper{
        width: 25%;
        padding: 10px;
        display: inline-block;
        vertical-align: top;
        }

    .admin_info_wrapper{
        display: none;
        width: 900px;
        padding: 30px;
        }

    .button_wrapper{
        margin-bottom: 30px;
        }

    .authmain{ display: flex; flex-wrap: wrap}

    .authmainlist{ margin: 5px}

</style>
